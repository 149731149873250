<template>
  <ElementsClearForm id="register-form" :submit-form="handleSubmit">
    <div class="flex flex-col justify-center h-full">
      <ElementsLogoClearLogo class="h-10 mb-5" />
      <h2 class="text-2xl text-gray-600 font-semibold 2xl:text-4xl 2xl:pb-5 pb-4">
        {{ $t('login_form.title') }}
      </h2>
      <div class="flex flex-col gap-3" tabindex="0">
        <ElementsInputsClearInput
          v-for="(myEl, i) in login"
          :key="`elm-${i}`"
          v-model="myEl.value"
          :class="myEl.divClass"
          :element-id="myEl.id"
          :error="myEl.error"
          :error-message="myEl.errorMessageKey ? $t(myEl.errorMessageKey) : ''"
          :image-key="myEl.imageKey"
          :input-class="myEl.inputClass"
          :is-image="myEl.isImage"
          :option-key="myEl.optionValue"
          :option-value="myEl.optionValue"
          :options="myEl.options"
          :place-holder="$t(myEl.label)"
          :type="myEl.type"
        >
        </ElementsInputsClearInput>

        <span v-if="!selectedRealm && user !== 'admin'" class="text-xs">{{ $t('login_form.selectRealmMsg') }}</span>
      </div>
      <template v-if="user === 'admin' || (selectedRealm && authentication_type !== AUTHENTICATION_TYPE.IDP)">
        <div class="flex justify-end w-full pt-2 py-4">
          <ElementsForgotPassword v-if="user === 'user'"></ElementsForgotPassword>
        </div>
        <div id="login-submit-wrapper" class="flex justify-center w-full" tabindex="0">
          <ElementsButtonsTheSubmit
            v-if="!authentication_type || authentication_type !== AUTHENTICATION_TYPE.IDP"
            id="login-submit"
            :background="`w-full button button--primary bg-blue-400 cursor-pointer`"
            :disabled="isLoginBtnDisabled"
            :value="`${$t('login_form.title')}`"
            type="submit"
          >
          </ElementsButtonsTheSubmit>
        </div>
      </template>

      <SessionExistConfirmation
        v-if="selectedRealm"
        v-model="isLoggedIn"
        :details="sessionPopUP"
        @handle-confirm-action="handleConfirmAction"
        @handle-reject-action="handleRejectAction"
      />

      <AuthLogin
        v-if="authentication_type === AUTHENTICATION_TYPE.IDP"
        :disabled="!selectedRealm"
        :selected-realm="+selectedRealm"
        class="mt-4"
      />
    </div>
  </ElementsClearForm>
</template>
<script setup>
import { ref, watch } from 'vue';
import { onMounted, onUnmounted, useCloneDeep, useI18n } from '#imports';
import LoginValidation from '@/validations/LoginValidation';
import useAuthStore from '@/stores/useAuthStore';
import useCachedRealms from '@/composables/useCachedRealms';
import { loginData } from '@/utils/formData/login';
import useClearFetchStore from '@/stores/useClearFetchStore';
import AuthLogin from './AuthLogin.vue';
import { AUTHENTICATION_TYPE } from '@/types';
import { KeyEventCode } from '@/utils/constants';
import SessionExistConfirmation from './SessionExistConfirmation.vue';
import useRealmStore from '@/stores/useRealmStore';

const errors = ref('');
const isLoaded = ref(false);
const selectedRealm = ref('');
const isLoggedIn = ref(false);
const authentication_type = ref(AUTHENTICATION_TYPE.LOCAL);
const { t } = useI18n();
const realmObj = useRealmStore();

const clearObj = useClearFetchStore();
const authObj = useAuthStore();

const isLoginBtnDisabled = ref(true);

const sessionPopUP = {
  iconClass: 'h-10 w-10 fill-yellow-500 mb-2',
  icon: 'notify-new',
  title: '',
  showCancelBtn: true,
  acceptBtnLabel: t('menu_items.resume'),
  rejectBtnLabel: t('menu_items.logout'),
  description: t('menu_items.session_exist')
};
const handleConfirmAction = () => {
  window.location.assign('/dashboard');
  return false;
};

// handle reject button
const handleRejectAction = async () => {
  await authObj.logout();
};

const props = defineProps({
  user: {
    type: String,
    required: true
  }
});

let login = null;
if (props.user === 'admin') {
  const adminLogin = useCloneDeep(loginData);
  delete adminLogin.realmId;
  login = ref(adminLogin);
} else {
  login = ref(useCloneDeep(loginData));
  delete login.value.email;
  delete login.value.password;
}
onMounted(async () => {
  clearObj.setErrorNull();
  if (authObj.getIsAuthenticated) {
    await authObj.handleRedirect();
  }

  if (!isLoaded.value && props.user === 'user' && !authObj.getIsAuthenticated) {
    const realms = await useCachedRealms();
    login.value.realmId.options = () => realms || [];
  } else {
    login.value.realmId.options = () => realmObj.getRealmData() || [];
  }
  clearObj.updatePendingState(false);
});

function afterValidation() {
  const data = {};
  for (const prop in login.value) {
    data[prop] = login.value[prop].value;
  }
  data.userType = props.user;

  // No error, continue with the sign in, e.g., by following the returned redirect:
  const loginReq = { ...data, authenticationType: 'local' };
  const res = authObj.signIn(loginReq);
  clearObj.updatePendingState(false);
  return res ? true : false;
}

const handleSubmit = () => {
  const clearFetchStore = JSON.parse(localStorage.getItem('clearFetch'));

  if (clearFetchStore?.loginData?.access_token) {
    isLoggedIn.value = true;
    return false;
  }

  let realmId = null;
  if (authentication_type.value === AUTHENTICATION_TYPE.IDP) return;
  if (login.value.realmId) {
    if (login.value.realmId.value.length === 0) return;
    else realmId = login.value.realmId.value;
  }
  for (const prop in login.value) {
    login.value[prop].error = false;
  }
  clearObj.updatePendingState(true);
  const validation = new LoginValidation(login.value.email.value, login.value.password.value, realmId);
  const validationResult = validation.checkValidations();

  if (Object.keys(validationResult)?.length) {
    errors.value = validationResult;
    for (const prop in errors.value) {
      if (prop === 'password' && props.user === 'admin') {
        login.value[prop].errorMessageKey = errors.value[prop].replace('.login', '.admin_login');
      } else {
        login.value[prop].errorMessageKey = errors.value[prop];
      }
      login.value[prop].error = true;
    }
    clearObj.updatePendingState(false);
    return false;
  } else {
    return afterValidation();
  }
};
watch(
  login.value,
  (newValue) => {
    isLoginBtnDisabled.value =
      !newValue.email.value || !newValue.password.value || (newValue.realmId && !newValue.realmId.value);
  },
  { deep: true }
);
watch(
  login.value,
  (newValue) => {
    //commented this functionality for disabling AuthC as per ticket CTR-3116
    // authentication_type.value = login.value.realmId
    //   .options()
    //   ?.find((opt) => opt?.id === newValue.realmId.value)?.authentication_type;

    selectedRealm.value = newValue.realmId.value;
    if (selectedRealm.value) {
      //commented this functionality for disabling AuthC as per ticket CTR-3116
      // if (authentication_type.value === AUTHENTICATION_TYPE.IDP) {
      //   delete login.value.email;
      //   delete login.value.password;
      // } else {
        login.value.email = loginData.email;
        login.value.password = loginData.password;
        if (realms?.value) {
          login.value.realmId.options = () => realms.value.data || [];
        }
      // }
    }
  },
  { deep: true }
);

const handleKeyDown = (event) => {
  if (event.key === KeyEventCode.Enter) {
    handleSubmit();
  }
};
onMounted(() => document.addEventListener('keydown', handleKeyDown));
onUnmounted(() => document.removeEventListener('keydown', handleKeyDown));
</script>
